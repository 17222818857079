//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { codeGenerateRunFileContent } from '@/api/modular/gen/codeGenerateManage'
export default {
  data () {
    return {
      activeKey: 'null',
      panes: [],
      indexCodeViewShow: false,
      tableLoading: false,
      visible: false
    }
  },
  methods: {
    /**
     * 打開界面
     */
    open (data) {
      this.indexCodeViewShow = true
      this.tableLoading = true
      codeGenerateRunFileContent(data).then((res) => {
        if (res.success) {
          let tabi = 1
          this.loadData = res.data
          this.loadData.forEach(item => {
            this.panes.push({ title: item.fileName, content: item.fileContent, key: 'tab' + tabi++ })
            this.code = item.fileContent
          })
        }
        this.activeKey = this.panes[0].key
        this.tableLoading = false
      })
    },
    copyContentCode (code) {
      var input = document.createElement('textarea')
      input.value = code
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
    },
    handleCancel () {
      this.$emit('ok')
      this.loadData = []
      this.panes = []
      this.indexCodeViewShow = false
    }
  }
}
