/**
 * 代碼生成
 *
 * @author yubaoshan
 * @date 2020/12/23 15:00
 */
import { axios } from '@/utils/request'

/**
 * 查詢列表
 *
 * @author yubaoshan
 * @date 2020/12/23 15:00
 */
export function codeGeneratePage (parameter) {
  return axios({
    url: '/codeGenerate/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 增加
 *
 * @author yubaoshan
 * @date 2020/12/23 15:00
 */
export function codeGenerateAdd (parameter) {
  return axios({
    url: '/codeGenerate/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 編輯
 *
 * @author yubaoshan
 * @date 2020/12/23 15:00
 */
export function codeGenerateEdit (parameter) {
  return axios({
    url: '/codeGenerate/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 刪除
 *
 * @author yubaoshan
 * @date 2020/12/23 15:00
 */
export function codeGenerateDelete (parameter) {
  return axios({
    url: '/codeGenerate/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 查詢當前數據庫用戶下的所有表
 *
 * @author yubaoshan
 * @date 2020/12/23 15:00
 */
export function codeGenerateInformationList (parameter) {
  return axios({
    url: '/codeGenerate/InformationList',
    method: 'get',
    params: parameter
  })
}

/**
 * 本地生成
 *
 * @author yubaoshan
 * @date 2020/12/23 15:00
 */
export function codeGenerateRunLocal (parameter) {
  return axios({
    url: '/codeGenerate/runLocal',
    method: 'post',
    data: parameter
  })
}

/**
 * 壓縮包方式下載
 *
 * @author yubaoshan
 * @date 2020/12/23 15:00
 */
export function codeGenerateRunDown (parameter) {
  return axios({
    url: '/codeGenerate/runDown',
    method: 'get',
    params: parameter,
    responseType: 'blob'
  })
}

/**
 * 代碼生產預覽接口
 *
 * @author yubaoshan
 * @date 2022/05/16
 */
export function codeGenerateRunFileContent (parameter) {
  return axios({
    url: '/codeGenerate/runFileContent',
    method: 'get',
    params: parameter
  })
}
